import axios from 'axios';
import api from './api';

let BASE_URL = 'http://10.10.115.209:3000'; // development
if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://crm.hongen.com'; // production
}

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

function checkLogin() {
  return new Promise((resolve, reject) => {
    const userId = api.getUserInfoItem('id');
    const token = api.getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const data = {
      user_id: userId,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/admin/checkLogin`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function userLogin(username, password) {
  return new Promise((resolve, reject) => {
    const data = {
      user: username,
      password,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/admin/login`,
      headers,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function userLogout() {
  return new Promise((resolve, reject) => {
    const userId = api.getUserInfoItem('id');
    const token = api.getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const data = {
      user_id: userId,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/admin/logout`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getSalesData(year) {
  return new Promise((resolve, reject) => {
    const userId = api.getUserInfoItem('id');
    const token = api.getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/admin/salesData`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getSalesman(userId, year) {
  return new Promise((resolve, reject) => {
    const token = api.getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/admin/salesman`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getMiscInfo() {
  return new Promise((resolve, reject) => {
    const userId = api.getUserInfoItem('id');
    const token = api.getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/admin/miscInfo`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  checkLogin,
  userLogin,
  userLogout,
  getSalesData,
  getSalesman,
  getMiscInfo,
};
