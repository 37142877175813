<template>
  <div class="goal">
    <van-row class="goal-title">
      <van-col span="12">&nbsp;</van-col>
      <van-col span="12">
        <van-row>
          <van-col span="6" class="center">计划</van-col>
          <van-col span="8" class="center">发货</van-col>
          <van-col span="7" class="center">进度</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-collapse v-model="activieProvince">
      <van-collapse-item
        v-for="(province, index) in agentList"
        :key="index"
        :name="index"
        :title="province.province"
      >
        <template #value>
          <van-row>
            <van-col span="7" class="right">{{ province.objectives || 0 }}</van-col>
            <van-col span="8" class="right">{{ Math.round(province.completed || 0) }}</van-col>
            <van-col span="8" class="right">{{ calcProgress(province.objectives, province.completed) }}%</van-col>
          </van-row>
        </template>
        <van-row
          v-for="(agent, index) in province.agentList"
          :key="index"
          class="goal-agent"
        >
          <van-col span="11" class="goal-agent-name">
            {{ agent.agent_name }}
          </van-col>
          <van-col span="12">
            <van-row>
              <van-col span="8" class="right">{{ agent.objectives }}</van-col>
              <van-col span="7" class="right">{{ Math.round(agent.completed) }}</van-col>
              <van-col span="7" class="right">{{ calcProgress(agent.objectives, agent.completed) }}%</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script lang="js">
import {
  Row,
  Col,
  List,
  Cell,
  Button,
  Collapse,
  CollapseItem,
} from 'vant';
import api from '@/lib/api';

export default {
  name: 'SaleGoalAreaView',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data() {
    return {
      userId: '',
      agentList: [],
      activieProvince: ['1'],
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      if (!value) return '0';
      let num = 0;
      if (typeof value === 'string') {
        num = (parseFloat(value) / 10000).toFixed(2);
      } else {
        num = (value / 10000).toFixed(2);
      }
      return `${num}`;
    },
  },
  watch: {
    year(val) {
      this.fetchAgentList(val);
    },
  },
  created() {
    this.userId = this.$route.params.userId;
  },
  mounted() {
    this.fetchAgentList(this.year);
  },
  methods: {
    fetchAgentList(year) {
      api.getYearAgentObjectives(this.userId, year).then((res) => {
        if (res.code === 200) {
          const provinceList = res.result.list.reduce((acc, cur) => {
            const province = acc.find((item) => item.province === cur.province);
            if (province) {
              province.agentList.push(cur);
              province.objectives += parseFloat(cur.objectives);
              province.completed += parseFloat(cur.completed);
            } else {
              acc.push({
                province: cur.province,
                agentList: [cur],
                objectives: parseFloat(cur.objectives),
                completed: parseFloat(cur.completed),
              });
            }
            return acc;
          }, []);
          this.agentList = provinceList;
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    calcProgress(objectives, completed) {
      if (!objectives) return 0;
      const obj = parseFloat(objectives);
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = completed / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  margin-top: 1rem;

  &-title {
    border-bottom: 1px solid #666;
    font-weight: bold;

    &-cell {
      line-height: 3rem;
      height: 3rem;
    }
  }

  &-agent {
    border-bottom: 1px solid #666;
    line-height: 2rem;

    &-name {

      > a {
        display: inline-block;
        font-size: 0.9rem;
        font-weight: 500;
        line-height: 1.2rem!important;
      }
    }
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.button-size {
  width: 48px;
}

.bottom-line {
  border-bottom: 1px solid #666;
}
</style>
