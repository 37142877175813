<template>
  <div class="dashboard">
    <div class="dashboard-header">
      <label for="year">
        年份：
        <select
          id="year"
          v-model="selectedYear"
          @change="getSalesData"
        >
          <option
            v-for="year in yearList"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
      </label>
    </div>
    <div class="dashboard-block">
      <div class="dashboard-block-content">
        <div class="dashboard-block-title">幼教渠道</div>
        <div class="dashboard-block-title">培训渠道</div>
        <div class="dashboard-block-title">合计</div>
      </div>
      <div class="dashboard-block-content">
        <div class="dashboard-block-item">
          <h4>{{ selectedYear }}年销售任务</h4>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">销售任务</span>
            <span class="dashboard-block-item-amount">{{ youjiaoChannelData.objectives | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">发货金额</span>
            <span class="dashboard-block-item-amount">{{ youjiaoChannelData.completed | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">完成比例</span>
            <span class="dashboard-block-item-amount">{{ calcPercent(youjiaoChannelData.objectives, youjiaoChannelData.completed) }}%</span>
          </div>
          <h4>截止当月销售任务</h4>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">销售任务</span>
            <span class="dashboard-block-item-amount">{{ youjiaoChannelData.cumulativeObjectives | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">累计发货</span>
            <span class="dashboard-block-item-amount">{{ youjiaoChannelData.cumulativeCompleted | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">累计进度</span>
            <span class="dashboard-block-item-amount">{{ calcPercent(youjiaoChannelData.cumulativeObjectives, youjiaoChannelData.cumulativeCompleted) }}%</span>
          </div>
        </div>
        <div class="dashboard-block-item">
          <h4>{{ selectedYear }}年销售任务</h4>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">销售任务</span>
            <span class="dashboard-block-item-amount">{{ peixunChannelData.objectives | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">发货金额</span>
            <span class="dashboard-block-item-amount">{{ peixunChannelData.completed | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">完成比例</span>
            <span class="dashboard-block-item-amount">{{ calcPercent(peixunChannelData.objectives, peixunChannelData.completed) }}%</span>
          </div>
          <h4>截止当月销售任务</h4>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">销售任务</span>
            <span class="dashboard-block-item-amount">{{ peixunChannelData.cumulativeObjectives | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">累计发货</span>
            <span class="dashboard-block-item-amount">{{ peixunChannelData.cumulativeCompleted | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">累计进度</span>
            <span class="dashboard-block-item-amount">{{ calcPercent(peixunChannelData.cumulativeObjectives, peixunChannelData.cumulativeCompleted) }}%</span>
          </div>
        </div>
        <div class="dashboard-block-item">
          <h4>{{ selectedYear }}年销售任务</h4>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">销售任务</span>
            <span class="dashboard-block-item-amount">{{ objectives | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">发货金额</span>
            <span class="dashboard-block-item-amount">{{ completed | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">完成比例</span>
            <span class="dashboard-block-item-amount">{{ calcPercent(objectives, completed) }}%</span>
          </div>
          <h4>截止当月销售任务</h4>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">销售任务</span>
            <span class="dashboard-block-item-amount">{{ cumulativeObjectives | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">累计发货</span>
            <span class="dashboard-block-item-amount">{{ cumulativeCompleted | formatNumberToTenThousand }}万</span>
          </div>
          <div class="dashboard-block-item-info">
            <span class="dashboard-block-item-title">累计进度</span>
            <span class="dashboard-block-item-amount">{{ calcPercent(cumulativeObjectives, cumulativeCompleted) }}%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-block">
      <h3>销售人员</h3>
      <div
        v-if="salesList && salesList.length > 0"
        class="dashboard-block-list"
      >
        <div
          v-for="salesman in salesList"
          :key="salesman.id"
          class="dashboard-block-salesman"
          @click="goSalesmanDetail(salesman.id,salesman.name)"
          @keydown.enter="goSalesmanDetail(salesman.id, salesman.name)"
        >
          <img src="@/assets/img4.png" alt="" />
          <div class="dashboard-block-salesman-info">
            <p class="dashboard-block-salesman-amount">{{ salesman.name }}</p>
            <p class="dashboard-block-salesman-title">{{ salesman.channel }}</p>
          </div>
          <div class="dashboard-block-salesman-data">
            <p class="dashboard-block-salesman-amount">{{ salesman.objectives | formatNumberToTenThousand }}万</p>
            <p class="dashboard-block-salesman-title border-bottom">年销售目标</p>
            <p class="dashboard-block-salesman-amount">{{ salesman.cumulativeObjectives | formatNumberToTenThousand }}万</p>
            <p class="dashboard-block-salesman-title">截止当月目标</p>
          </div>
          <div class="dashboard-block-salesman-data">
            <p class="dashboard-block-salesman-amount">{{ salesman.completed | formatNumberToTenThousand }}万</p>
            <p class="dashboard-block-salesman-title">销售金额</p>
          </div>
          <div class="dashboard-block-salesman-data">
            <p class="dashboard-block-salesman-amount">{{ calcPercent(salesman.objectives, salesman.completed) }}%</p>
            <p class="dashboard-block-salesman-title border-bottom">完成比例</p>
            <p class="dashboard-block-salesman-amount">{{ calcPercent(salesman.cumulativeObjectives, salesman.cumulativeCompleted) }}%</p>
            <p class="dashboard-block-salesman-title">截止当月比例</p>
          </div>
        </div>
      </div>
      <div
        v-else
        class="dashboard-block-list"
      >
        <p style="padding-left:40px;">暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script lang="javascript">
import admin from '@/lib/admin';
import api from '@/lib/api';

export default {
  name: 'AdminDashboardView',
  data() {
    return {
      yearList: [],
      selectedYear: new Date().getFullYear(),
      objectives: 0,
      completed: 0,
      cumulativeObjectives: 0,
      cumulativeCompleted: 0,
      salesList: [],
      channelData: [], // 渠道数据 {channel: '渠道名称', objectives: 0, completed: 0, cumulativeObjectives: 0, cumulativeCompleted: 0}
      youjiaoChannelData: {},
      peixunChannelData: {},
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      if (!value) return '0';
      const num = (value / 10000).toFixed(2);
      return `${num}`;
    },
  },
  beforeMount() {
    const year = new Date().getFullYear();
    for (let i = 2024; i <= year; i += 1) {
      this.yearList.push(i);
    }
  },
  mounted() {
    this.getSalesData();
  },
  methods: {
    getSalesData() {
      admin.getSalesData(this.selectedYear)
        .then((res) => {
          if (res.code === 200) {
            this.objectives = res.result.objectives || 0;
            this.completed = res.result.completed || 0;
            this.cumulativeObjectives = res.result.cumulativeObjectives || 0;
            this.cumulativeCompleted = res.result.cumulativeCompleted || 0;
            const list = res.result.sales || [];
            this.salesList = list.sort((a, b) => {
              let percentA = 0;
              let percentB = 0;
              if (parseFloat(a.cumulativeObjectives) !== 0 && parseFloat(b.cumulativeObjectives) !== 0) {
                percentA = parseFloat(a.cumulativeCompleted) / parseFloat(a.cumulativeObjectives);
                percentB = parseFloat(b.cumulativeCompleted) / parseFloat(b.cumulativeObjectives);
              } else {
                percentA = parseFloat(a.completed) || 0;
                percentB = parseFloat(b.completed) || 0;
              }
              return percentB - percentA;
            });
            this.channelData = [];
            this.salesList.forEach((item) => {
              const { channel } = item;
              const channelData = this.channelData.find((c) => c.channel === channel);
              if (channelData) {
                channelData.objectives += parseFloat(item.objectives || 0);
                channelData.completed += parseFloat(item.completed || 0);
                channelData.cumulativeObjectives += parseFloat(item.cumulativeObjectives || 0);
                channelData.cumulativeCompleted += parseFloat(item.cumulativeCompleted || 0);
              } else {
                this.channelData.push({
                  channel,
                  objectives: parseFloat(item.objectives || 0),
                  completed: parseFloat(item.completed || 0),
                  cumulativeObjectives: parseFloat(item.cumulativeObjectives || 0),
                  cumulativeCompleted: parseFloat(item.cumulativeCompleted || 0),
                });
              }
            });
            this.youjiaoChannelData = this.channelData.find((c) => c.channel === '幼教') || {};
            this.peixunChannelData = this.channelData.find((c) => c.channel === '培训') || {};
          } else {
            this.$toast(res.message);
          }
        }).catch((err) => {
          this.$toast(err.message);
        });
    },
    goSalesmanDetail(userId, userName) {
      api.setUserInfoItem('currentSalesman', userName);
      this.$router.push(`/admin/salesman/${userId}`);
    },
    calcPercent(objectives, completed) {
      const obj = parseFloat(objectives) || 0;
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = parseFloat(completed) / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 20px 50px;
  width: calc(100% - 100px);

  &-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0;
      padding: 0;

      select {
        font-size: 16px;
        margin-left: 10px;
        padding: 5px;
      }
    }
  }

  &-block {
    margin: 0;
    padding: 20px 0 0 0;
    width: 100%;

    &-title {
      font-size: 22px;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      margin: 0;
      padding: 0;
      text-align: left;
      width: 255px;
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-item {
      align-items: center;
      background-color: #ffffff;
      border-radius: 25px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 20px;
      padding-bottom: 20px;
      text-align: left;
      width: 255px;

      h4 {
        color: #505887;
        font-size: 20px;
        font-weight: 500;
        margin: 20px 10px 6px 10px;
        text-align: left;
        width: calc(100% - 20px);
      }

      > img {
        height: 70px;
        margin-left: 36px;
        width: 70px;
      }

      &-info {
        margin-left: 16px;
        width: calc(100% - 16px);
      }

      &-title {
        color: #718EBF;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-left: 10px;
      }

      &-amount {
        color: #505887;
        font-size: 20px;
        font-weight: 500;
        margin-left: 20px;
      }
    }

    &-list {
      overflow: auto;
      width: 100%;
    }

    &-salesman {
      align-items: center;
      background-color: #ffffff;
      border-radius: 25px;
      display: flex;
      justify-content: space-between;
      height: 120px;
      margin-top: 20px;
      width: 100%;

      > img {
        height: 60px;
        margin-left: 36px;
        width: 62px;
      }

      &-info {
        text-align: left;
        width: 160px;

        > p {
          padding-left: 25px;
        }
      }

      &-data {
        flex: 1;
        padding: 0 20px;
        text-align: center;
      }

      &-title {
        color: #718EBF;
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 3px;
      }

      &-amount {
        color: #505887;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

.border-bottom {
  border-bottom: 1px dashed #E5E9F2;
}
</style>
