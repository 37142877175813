<template>
  <div class="goal">
    <van-row class="goal-title center">
      <van-col span="12" class="left">&nbsp;&nbsp;月份</van-col>
      <van-col span="3" class="right">计划</van-col>
      <van-col span="4" class="right">发货</van-col>
      <van-col span="3" class="right">进度</van-col>
    </van-row>
    <van-collapse v-model="activeMonth">
      <van-collapse-item
        v-for="(month, index) in monthList"
        :key="index"
        :name="month.month"
        :title="`${month.month} 月`"
      >
        <template #title>
          <div class="bold">{{ month.month }} 月</div>
        </template>
        <template #value>
          <van-row>
            <van-col span="8" class="right">{{ month.objectives }}</van-col>
            <van-col span="9" class="right">{{ Math.round(month.completed) }}</van-col>
            <van-col span="7" class="right">{{ calcProgress(month.objectives, month.completed) }}%</van-col>
          </van-row>
        </template>
        <van-row
          v-for="agent in month.agent_list"
          :key="agent.agent_id"
          gutter="4"
        >
          <van-col span="12">{{ agent.agent_name }}</van-col>
          <van-col span="4" class="right">{{ agent.objectives }}</van-col>
          <van-col span="4" class="right">{{ Math.round(agent.completed) }}</van-col>
          <van-col span="3" class="right">{{ calcProgress(agent.objectives, agent.completed) }}%</van-col>
        </van-row>
      </van-collapse-item>
    </van-collapse>
    <!--
    <van-list>
      <van-row
        v-for="(month, index) in monthList"
        :key="index"
        class="goal-month center"
      >
        <van-col span="6">{{ month.month }} 月</van-col>
        <van-col span="6">{{ month.objectives | formatNumberToTenThousand }}万</van-col>
        <van-col span="6">{{ month.completed | formatNumberToTenThousand }}万</van-col>
        <van-col span="6">{{ calcProgress(month.objectives, month.completed) }}%</van-col>
      </van-row>
    </van-list>
    -->
  </div>
</template>

<script lang="js">
import {
  Row,
  Col,
  List,
  Cell,
  Button,
  Collapse,
  CollapseItem,
} from 'vant';
import api from '@/lib/api';

export default {
  name: 'SaleGoalMonthView',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data() {
    return {
      userId: '',
      monthList: [],
      activeMonth: ['1'],
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      if (!value) return '0';
      const num = (value / 10000).toFixed(2);
      return `${num}`;
    },
  },
  watch: {
    year() {
      this.getMonthObjectiveList();
    },
  },
  created() {
    this.userId = this.$route.params.userId;
  },
  mounted() {
    this.getMonthObjectiveList();
  },
  methods: {
    getMonthObjectiveList() {
      api.getMonthObjectives(this.userId, this.year).then((res) => {
        if (res.code === 200) {
          this.monthList = res.result.monthly_list.sort((a, b) => a.month - b.month);
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    calcProgress(objectives, completed) {
      if (!objectives) return 0;
      const obj = parseFloat(objectives, 10);
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = completed / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  margin-top: 1rem;

  &-title {
    border-bottom: 1px solid #666;
    font-weight: bold;
    height: 2.5rem;
    line-height: 2.5rem;
  }

  &-month {
    border-bottom: 1px solid #666;
    height: 2rem;
    line-height: 2rem;
  }
}

.bottom-line {
  border-bottom: 1px solid #666;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}
</style>
