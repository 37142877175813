import axios from 'axios';

let BASE_URL = 'http://10.10.115.209:3000'; // development
if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://crm.hongen.com'; // production
}

const LOCAL_STORAGE_USER = '__gh_sm_user';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

function getUserInfoItem(item) {
  let res = '';
  const userStr = localStorage.getItem(LOCAL_STORAGE_USER);
  if (userStr && userStr.length > 0) {
    const user = JSON.parse(userStr);
    res = user[item] || '';
  }
  return res;
}

function setUserInfoItem(item, value) {
  const userStr = localStorage.getItem(LOCAL_STORAGE_USER) || '{}';
  const user = JSON.parse(userStr) || {};
  user[item] = value;
  localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
}

function setUserInfoData(data) {
  if (typeof data !== 'object') { return; }
  const userStr = localStorage.getItem(LOCAL_STORAGE_USER) || '{}';
  const user = JSON.parse(userStr) || {};
  Object.keys(data).forEach((key) => {
    user[key] = data[key];
  });
  localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
}

function clearUserInfo() {
  localStorage.removeItem(LOCAL_STORAGE_USER);
}

function checkLogin() {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const data = {
      user_id: userId,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/checkLogin`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function userLogin(username, password) {
  return new Promise((resolve, reject) => {
    const data = {
      user: username,
      password,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/login`,
      headers,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getUser(userId) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/user`,
      headers: newHeaders,
      params: { user_id: userId },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getYearObjectives(userId, year) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/yearly`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getYearAgentObjectives(userId, year) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/yearly/agent`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getYearProductObjectives(userId, year) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/yearly/product`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getMonthlyAgentObjectives(userId, year, month) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
      month,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/agent`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getProductObjectives(userId, year) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/product`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getMonthObjectives(userId, year) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/monthly`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getAgentProductObjectives(userId, agentId, year) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      agent_id: agentId,
      year,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/objective/agent/product`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getLogTypeList(parentId = 0) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      parent_id: parentId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/type`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getMonthDailyLogList(year, month) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
      year,
      month,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/month`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function dailyCheckin(dateStr, logTypeId, address, location, locationCity, transportStartTime, transportEndTime, photoUrl) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const data = {
      user_id: userId,
      date: dateStr,
      log_type_id: logTypeId,
      address,
      location,
      location_city: locationCity,
      transport_start_time: transportStartTime,
      transport_end_time: transportEndTime,
      photo: photoUrl,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/log/checkin`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function updateDailyCheckin(logId, logTypeId, address, location, locationCity, transportStartTime, transportEndTime, photoUrl) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const data = {
      id: logId,
      user_id: userId,
      log_type_id: logTypeId,
      address,
      location,
      location_city: locationCity,
      transport_start_time: transportStartTime,
      transport_end_time: transportEndTime,
      photo: photoUrl,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/log/checkin/edit`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getLog(logId) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      log_id: logId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/checkin`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getLogDetail(logId) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      log_daily_id: logId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/checkin`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function addLogItem(itemData) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const {
      logId,
      logTypeId,
      agentId,
      schoolId,
      isChoolRegistered,
      photoUrl,
      photoTime,
      photoLocation,
      photoAddress,
      address,
      actScale,
      actAduienceCount,
      actSchoolIdList,
    } = itemData;
    const data = {
      log_daily_id: logId,
      log_type_id: logTypeId,
      eclass_agent_id: agentId,
      school_id: schoolId,
      school_registered: isChoolRegistered,
      photo: photoUrl,
      photo_time: photoTime,
      photo_location: photoLocation,
      photo_address: photoAddress,
      address,
      act_scale: actScale,
      act_aduience_count: actAduienceCount,
      act_schools: actSchoolIdList ? JSON.stringify(actSchoolIdList) : '',
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/log/checkin/item`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function editLogItem(itemId, itemData) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const {
      logTypeId,
      agentId,
      schoolId,
      isChoolRegistered,
      photoUrl,
      photoTime,
      photoLocation,
      photoAddress,
      address,
      actScale,
      actAduienceCount,
      actSchoolIdList,
    } = itemData;
    const data = {
      id: itemId,
      log_type_id: logTypeId,
      eclass_agent_id: agentId,
      school_id: schoolId,
      school_registered: isChoolRegistered,
      photo: photoUrl,
      photo_time: photoTime,
      photo_location: photoLocation,
      photo_address: photoAddress,
      address,
      act_scale: actScale,
      act_aduience_count: actAduienceCount,
      act_schools: actSchoolIdList ? JSON.stringify(actSchoolIdList) : '',
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/log/checkin/item/edit`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getLogItem(logItemId) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0 || !logItemId || logItemId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      id: logItemId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/checkin/item`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
function getLogItemActivityList(logItemId) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0 || !logItemId || logItemId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      log_item_id: logItemId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/checkin/item/activity`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
*/

function addLogItemActivity(logItemId, activityData) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0 || !logItemId || logItemId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const {
      logTypeId,
      promotionProductId,
      promotionPotential,
      coursewareInstallCount,
      visitSchoolReason,
      visitSchoolResult,
      visitSchoolDesc,
      trialProductId,
      trialClassCount,
      trialStudentCount,
      trialStartAt,
      trialEndAt,
      promotionAgreement,
      promotionEstimatedAmount,
      trainingProductId,
      trainingAudienceAmount,
      planProductId,
      planStartAt,
      planEndAt,
      planAmount,
      deliveryNewOrder,
      deliveryNewOrderAmount,
      paymentNewOrder,
      paymentNewOrderAmount,
      serviceContent,
    } = activityData;
    const data = {
      user_id: userId,
      log_item_id: logItemId,
      log_type_id: logTypeId,
      promotion_product_id: promotionProductId,
      promotion_potential: promotionPotential,
      courseware_install_count: coursewareInstallCount,
      visit_school_reason: visitSchoolReason,
      visit_school_result: visitSchoolResult,
      visit_school_desc: visitSchoolDesc,
      trial_product_id: trialProductId,
      trial_class_count: trialClassCount,
      trial_student_count: trialStudentCount,
      trial_start_at: trialStartAt,
      trial_end_at: trialEndAt,
      promotion_agreement: promotionAgreement,
      promotion_estimated_amount: promotionEstimatedAmount,
      training_product_id: trainingProductId,
      training_audience_amount: trainingAudienceAmount,
      plan_product_id: planProductId,
      plan_start_at: planStartAt,
      plan_end_at: planEndAt,
      plan_amount: planAmount,
      delivery_new_order: deliveryNewOrder,
      delivery_new_order_amount: deliveryNewOrderAmount,
      payment_new_order: paymentNewOrder,
      payment_new_order_amount: paymentNewOrderAmount,
      service_content: serviceContent,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/log/checkin/item/activity`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function editLogItemActivity(activityId, activityData) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!userId || userId.length === 0 || !token || token.length === 0 || !activityId || activityId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const {
      promotionProductId,
      promotionPotential,
      coursewareInstallCount,
      visitSchoolReason,
      visitSchoolResult,
      visitSchoolDesc,
      trialProductId,
      trialClassCount,
      trialStudentCount,
      trialStartAt,
      trialEndAt,
      promotionAgreement,
      promotionEstimatedAmount,
      trainingProductId,
      trainingAudienceAmount,
      planProductId,
      planStartAt,
      planEndAt,
      planAmount,
      deliveryNewOrder,
      deliveryNewOrderAmount,
      paymentNewOrder,
      paymentNewOrderAmount,
      serviceContent,
    } = activityData;
    const data = {
      user_id: userId,
      id: activityId,
      promotion_product_id: promotionProductId,
      promotion_potential: promotionPotential,
      courseware_install_count: coursewareInstallCount,
      visit_school_reason: visitSchoolReason,
      visit_school_result: visitSchoolResult,
      visit_school_desc: visitSchoolDesc,
      trial_product_id: trialProductId,
      trial_class_count: trialClassCount,
      trial_student_count: trialStudentCount,
      trial_start_at: trialStartAt,
      trial_end_at: trialEndAt,
      promotion_agreement: promotionAgreement,
      promotion_estimated_amount: promotionEstimatedAmount,
      training_product_id: trainingProductId,
      training_audience_amount: trainingAudienceAmount,
      plan_product_id: planProductId,
      plan_start_at: planStartAt,
      plan_end_at: planEndAt,
      plan_amount: planAmount,
      delivery_new_order: deliveryNewOrder,
      delivery_new_order_amount: deliveryNewOrderAmount,
      payment_new_order: paymentNewOrder,
      payment_new_order_amount: paymentNewOrderAmount,
      service_content: serviceContent,
    };
    axios({
      method: 'post',
      url: `${BASE_URL}/api/log/checkin/item/activity/edit`,
      headers: newHeaders,
      data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getAgentList(userId) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/agent`,
      headers: newHeaders,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getSchoolList(agentId) {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0 || !agentId || agentId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      agent_id: agentId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/agent/school`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getProductList(userId) {
  return new Promise((resolve, reject) => {
    const token = getUserInfoItem('token');
    if (!token || token.length === 0 || !userId || userId.length === 0) {
      reject(new Error('false'));
      return;
    }
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/log/product`,
      headers: newHeaders,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getStandingBookAgentStatistic() {
  return new Promise((resolve, reject) => {
    const userId = getUserInfoItem('id');
    const token = getUserInfoItem('token');
    const newHeaders = { ...headers, Authorization: `Bearer ${token}` };
    const params = {
      user_id: userId,
    };
    axios({
      method: 'get',
      url: `${BASE_URL}/api/standingBook/agentStatistic`,
      headers: newHeaders,
      params,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default {
  BASE_URL,
  getUserInfoItem,
  setUserInfoItem,
  setUserInfoData,
  clearUserInfo,
  checkLogin,
  userLogin,
  getUser,
  getYearObjectives,
  getYearAgentObjectives,
  getYearProductObjectives,
  getMonthlyAgentObjectives,
  getProductObjectives,
  getMonthObjectives,
  getAgentProductObjectives,
  getMonthDailyLogList,
  getLogTypeList,
  dailyCheckin,
  updateDailyCheckin,
  getLog,
  getLogDetail,
  addLogItem,
  editLogItem,
  getLogItem,
  // getLogItemActivityList,
  addLogItemActivity,
  editLogItemActivity,
  getAgentList,
  getSchoolList,
  getProductList,
  getStandingBookAgentStatistic,
};
