<template>
  <div class="dashboard">
    <div class="dashboard-header">
      <label for="year">
        年份：
        <select
          id="year"
          v-model="selectedYear"
          @change="getSalesman(currentUserId, selectedYear)"
        >
          <option
            v-for="year in yearList"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
      </label>
    </div>
    <div class="dashboard-block">
      <h3>{{ selectedYear }}年销售任务</h3>
      <div class="dashboard-block-content">
        <div class="dashboard-block-item">
          <img src="@/assets/img3.png" alt="" />
          <div class="dashboard-block-item-info">
            <p class="dashboard-block-item-title">销售任务</p>
            <p class="dashboard-block-item-amount">{{ objectives | formatNumberToTenThousand }}万</p>
          </div>
        </div>
        <div class="dashboard-block-item">
          <img src="@/assets/img2.png" alt="" />
          <div class="dashboard-block-item-info">
            <p class="dashboard-block-item-title">发货金额</p>
            <p class="dashboard-block-item-amount">{{ completed | formatNumberToTenThousand }}万</p>
          </div>
        </div>
        <div class="dashboard-block-item">
          <img src="@/assets/img1.png" alt="" />
          <div class="dashboard-block-item-info">
            <p class="dashboard-block-item-title">完成比例</p>
            <p class="dashboard-block-item-amount">{{ calcPercent(objectives, completed) }}%</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-block">
      <h3>截止当月销售任务</h3>
      <div class="dashboard-block-content" style="justify-content: space-between;">
        <div class="dashboard-block-item">
          <img src="@/assets/img3.png" alt="" />
          <div class="dashboard-block-item-info">
            <p class="dashboard-block-item-title">销售任务</p>
            <p class="dashboard-block-item-amount">{{ cumulativeObjectives | formatNumberToTenThousand }}万</p>
          </div>
        </div>
        <div class="dashboard-block-item">
          <img src="@/assets/img2.png" alt="" />
          <div class="dashboard-block-item-info">
            <p class="dashboard-block-item-title">累计发货</p>
            <p class="dashboard-block-item-amount">{{ cumulativeCompleted | formatNumberToTenThousand }}万</p>
          </div>
        </div>
        <div class="dashboard-block-item">
          <img src="@/assets/img1.png" alt="" />
          <div class="dashboard-block-item-info">
            <p class="dashboard-block-item-title">累计进度</p>
            <p class="dashboard-block-item-amount">{{ calcPercent(cumulativeObjectives, cumulativeCompleted) }}%</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-block">
      <SaleGoalView :year="selectedYear" />
    </div>
  </div>
</template>

<script lang="javascript">
import admin from '@/lib/admin';
import SaleGoalView from '@/views/SaleGoalView.vue';

export default {
  name: 'AdminSalesmanView',
  components: {
    SaleGoalView,
  },
  data() {
    return {
      userName: '',
      channel: '',
      objectives: 0,
      completed: 0,
      cumulativeObjectives: 0,
      cumulativeCompleted: 0,
      monthList: [],
      selectedYear: 0,
      yearList: [],
      currentUserId: '',
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      if (!value) return '0';
      const num = (value / 10000).toFixed(2);
      return `${num}`;
    },
  },
  beforeMount() {
    const year = new Date().getFullYear();
    for (let i = 2024; i <= year; i += 1) {
      this.yearList.push(i);
    }
  },
  mounted() {
    this.selectedYear = new Date().getFullYear();
    const { userId } = this.$route.params;
    this.currentUserId = userId;
    this.getSalesman(userId, this.selectedYear);
  },
  methods: {
    getSalesman(userId, year) {
      admin.getSalesman(userId, year)
        .then((res) => {
          if (res.code === 200) {
            this.userName = res.result.name || '';
            this.channel = res.result.channel || '';
            this.objectives = res.result.objectives || 0;
            this.completed = res.result.completed || 0;
            this.monthList = res.result.monthList || [];
            this.cumulativeObjectives = res.result.cumulativeObjectives || 0;
            this.cumulativeCompleted = res.result.cumulativeCompleted || 0;
          } else {
            this.$toast(res.message);
          }
        }).catch((err) => {
          this.$toast(err.message);
        });
    },
    calcPercent(objectives, completed) {
      const obj = parseFloat(objectives) || 0;
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = completed / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 20px 50px;
  width: calc(100% - 100px);

  &-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin: 0;
      padding: 0;

      select {
        font-size: 16px;
        margin-left: 10px;
        padding: 5px;
      }
    }
  }

  &-block {
    margin: 0;
    padding: 20px 0 0 0;
    width: 100%;

    > h3 {
      font-size: 22px;
      font-weight: 500;
      height: 30px;
      line-height: 30px;
      margin: 0;
      padding: 0;
    }

    &-content {
      display: flex;
      justify-content: space-between;
    }

    &-item {
      align-items: center;
      background-color: #ffffff;
      border-radius: 25px;
      display: flex;
      height: 120px;
      margin-top: 20px;
      width: 255px;

      > img {
        height: 70px;
        margin-left: 36px;
        width: 70px;
      }

      &-info {
        margin-left: 16px;
      }

      &-title {
        color: #718EBF;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      &-amount {
        color: #505887;
        font-size: 24px;
        font-weight: 500;
      }
    }

    &-list {
      overflow: auto;
      width: 100%;
    }

    &-salesman {
      align-items: center;
      background-color: #ffffff;
      border-radius: 25px;
      display: flex;
      justify-content: space-between;
      height: 120px;
      margin-top: 20px;
      width: 100%;

      > img {
        height: 60px;
        margin-left: 36px;
        width: 62px;
      }

      &-info {
        text-align: left;
        width: 160px;

        > p {
          padding-left: 25px;
        }
      }

      &-data {
        flex: 1;
        text-align: center;
      }

      &-title {
        color: #718EBF;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      &-amount {
        color: #505887;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}
</style>
