<template>
  <div class="goal">
    <van-row class="goal-title">
      <van-col span="9" class="goal-title-cell center">产品名称</van-col>
      <van-col span="15">
        <van-row>
          <van-col span="6" class="center">
            <van-button
              type="default"
              size="small"
              plain
              hairline
              class="button-size"
              @click="month -= 1"
              :disabled="month === 1"
            >
              &lt;
            </van-button>
          </van-col>
          <van-col span="12" class="bottom-line center">{{ month }}月目标</van-col>
          <van-col span="6" class="center">
            <van-button
              type="default"
              size="small"
              plain
              hairline
              class="button-size"
              @click="month += 1"
              :disabled="month === 12"
            >
              &gt;
            </van-button>
          </van-col>
        </van-row>
        <van-row class="right pr-8">
          <van-col span="8">计划</van-col>
          <van-col span="8">发货</van-col>
          <van-col span="8">进度</van-col>
        </van-row>
      </van-col>
    </van-row>
    <van-list>
      <van-row
        v-for="(product, index) in productList"
        :key="index"
        class="goal-product ph-8"
      >
        <van-col span="9">{{ product.product_name }}</van-col>
        <van-col span="15">
          <van-row>
            <van-col span="8" class="right">{{ product.objectives[month - 1].objectives }}</van-col>
            <van-col span="8" class="right">{{ Math.round(product.objectives[month - 1].completed) }}</van-col>
            <van-col span="8" class="right">{{ calcProgress(product.objectives[month - 1].objectives, product.objectives[month - 1].completed) }}%</van-col>
          </van-row>
        </van-col>
      </van-row>
    </van-list>
  </div>
</template>

<script lang="js">
import {
  Row,
  Col,
  List,
  Cell,
  Button,
} from 'vant';
import api from '@/lib/api';

export default {
  name: 'SaleGoalProductMonthView',
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [Cell.name]: Cell,
    [Button.name]: Button,
  },
  props: {
    year: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },
  data() {
    return {
      userId: '',
      productList: [],
      month: 1,
    };
  },
  filters: {
    formatNumberToTenThousand(value) {
      if (!value) return '0';
      const num = (value / 10000).toFixed(2);
      return `${num}`;
    },
  },
  watch: {
    year() {
      this.getProductList();
    },
  },
  created() {
    this.userId = this.$route.params.userId;
  },
  mounted() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      api.getProductObjectives(this.userId, this.year).then((res) => {
        if (res.code === 200) {
          const list = [...res.result.product_list];
          for (let i = 0; i < list.length; i += 1) {
            if (list[i].objectives.length !== 12) {
              const newObjectives = [];
              for (let j = 0; j < 12; j += 1) { // 12个月
                const monthObjective = list[i].objectives.find((item) => item.month === j + 1);
                if (monthObjective) {
                  newObjectives.push({ ...monthObjective });
                } else {
                  newObjectives.push({
                    month: j + 1,
                    objectives: 0,
                    completed: 0,
                  });
                }
              }
              list[i].objectives = [...newObjectives];
            }
          }
          this.productList = list;
        } else {
          this.$toast(res.message);
        }
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    calcProgress(objectives, completed) {
      if (!objectives) return 0;
      const obj = parseFloat(objectives);
      let percent = 0;
      if (obj === 0) {
        percent = 0;
      } else {
        percent = completed / obj * 100;
      }
      return percent.toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.goal {
  margin-top: 1rem;

  &-title {
    border-bottom: 1px solid #666;
    font-weight: bold;

    &-cell {
      line-height: 3rem;
      height: 3rem;
    }
  }

  &-product {
    border-bottom: 1px solid #666;
    line-height: 2rem;
  }
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.ph-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.button-size {
  width: 48px;
}

.bottom-line {
  border-bottom: 1px solid #666;
}
</style>
